import React from "react";
import Headroom from "react-headroom";
import { SkipNavContent } from "@reach/skip-nav";

import GridRow from "../GridRow";
import Box from "../Box";
import Grid from "../Grid";
import List from "../List";
import Link from "../Link";
import Logo from "../Logo";
import Button from "../Button";

import useGlobalState from "../../../utils/useGlobalState";
import { linkResolver } from "../../../utils/links";
import { gradientBgAnim, navLinksActive, ulReset } from "../../../utils/styles";

import { ReactComponent as DownArrow } from "../../../images/icons/down-simple-arrow.svg";

const NavItem = (props) => (
  <Box
    as="li"
    sx={{
      display: "flex",
      position: "relative",
      justifyContent: "center",
      pb: 2,
      mb: -2,
      "> .dropdown": {
        position: "absolute",
        opacity: 0,
        visibility: "hidden",
        transition: (theme) => `all ${theme.transitions.fast}`,
        transform: "translateY(-10px)",
        top: "100%",
        margin: 0,
        px: 0,
        borderRadius: "default",
        boxShadow: "button",
        backgroundColor: "white",
        color: "text",
        textTransform: "none",
        width: "150%",
        fontSize: 3,
        fontWeight: "bold",
        lineHeight: "heading",
        overflow: "hidden",
        li: {
          ...ulReset,
        },
        "li + li": {
          borderTop: "1px solid",
          borderColor: "gray.1",
        },
      },
      "> svg": {
        transition: (theme) => `all ${theme.transitions.fast}`,
        mt: "9px",
        ml: "3px",
      },
      "&:hover > .dropdown": {
        opacity: 1,
        visibility: "visible",
        transform: "translateY(0)",
      },
      "&:hover > svg": {
        transform: "rotate(0.5turn)",
      },
    }}
    {...props}
  />
);

const Header = ({ animateIn, sx, ...otherProps }) => {
  const [mobileMenuModule, setMobileMenu] = React.useState();
  const containerRef = React.useRef();
  const [globalState] = useGlobalState();
  const { sanityNavigation } = globalState;

  React.useEffect(() => {
    import("../MobileMenu").then((module) => {
      setMobileMenu(module);
    });
  }, []);

  const MobileMenu = mobileMenuModule && mobileMenuModule.default;

  // Hack to avoid a jump on the header menu when opening the sidebar
  // const onSidebarToggle = React.useCallback((isOpen) => {
  //   const el = containerRef.current.querySelector(".headroom--scrolled");

  //   if (!el) return;

  //   const scrollbarGap =
  //     window.innerWidth - document.documentElement.clientWidth;

  //   setTimeout(() => {
  //     el.style.paddingRight = isOpen ? `${scrollbarGap}px` : 0;
  //   }, 0);
  // }, []);
  const navLinks = sanityNavigation.header;

  return (
    <React.Fragment>
      <Box
        ref={containerRef}
        className="header-container"
        sx={{
          position: "relative",
          zIndex: "header",
          ...sx,
        }}
        {...otherProps}
      >
        <Headroom>
          <GridRow
            as="header"
            sx={{
              position: "relative",
              zIndex: 1,
              transition: (theme) => `background ${theme.transitions.normal}`,
              width: "100%",
              color: "inverted",
              py: [3, 4, 3],
              transform: "translateZ(0)",
              ".headroom--scrolled &": {
                background: "white",
              },
            }}
          >
            <GridRow.Col>
              <Grid
                sx={{
                  position: "relative",
                  gridTemplateColumns: "max-content 1fr",
                  alignItems: "center",
                }}
              >
                <Link
                  sx={{
                    transition: (theme) => `color ${theme.transitions.normal}`,
                    ".headroom--scrolled &": {
                      color: "text",
                    },
                  }}
                  to="/"
                >
                  <Logo />
                </Link>
                <List
                  horizontal
                  gap={4}
                  sx={{
                    display: ["none", "none", "grid"],
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  {navLinks.map((link) => {
                    const linkProps = linkResolver(link);
                    return (
                      <NavItem key={linkProps.to || linkProps.href}>
                        <Link
                          variant="caps"
                          sx={{
                            display: "block",
                            ...navLinksActive,
                            ".headroom--scrolled &": {
                              color: "text",
                            },
                          }}
                          {...linkProps}
                        />
                        {!!link.children.length && (
                          <>
                            <DownArrow width={12} />
                            <ul className="dropdown">
                              {link.children.map((link) => (
                                <li key={link.to || link.href}>
                                  <Link
                                    sx={(theme) => ({
                                      position: "relative",
                                      display: "block",
                                      p: 2,
                                      "&:before": {
                                        position: "absolute",
                                        display: "block",
                                        borderRadius: "inherit",
                                        content: '" "',
                                        top: "-1px",
                                        left: "-1px",
                                        right: "-1px",
                                        bottom: "-1px",
                                        opacity: 0,
                                        visibility: "hidden",
                                        zIndex: -1,
                                        transition: "inherit",
                                        ...gradientBgAnim,
                                      },
                                      "&:hover": {
                                        ...theme.buttons["default"]["&:hover"],
                                        "&:before": {
                                          opacity: 1,
                                          visibility: "visible",
                                        },
                                      },
                                    })}
                                    {...linkResolver(link)}
                                  />
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                      </NavItem>
                    );
                  })}
                  <li>
                    <Button
                      variant="small"
                      sx={{
                        ".headroom--scrolled &": {
                          color: "white",
                          backgroundColor: "black",
                        },
                      }}
                      {...linkResolver(sanityNavigation.headerButton[0])}
                    />
                  </li>
                </List>
                {mobileMenuModule && (
                  <MobileMenu
                    sx={{
                      display: [null, null, "none"],
                      position: "absolute",
                      right: 0,
                      alignSelf: "center",
                    }}
                    navLinks={navLinks}
                    button={sanityNavigation.headerButton[0]}
                  />
                )}
              </Grid>
            </GridRow.Col>
          </GridRow>
        </Headroom>
      </Box>
      <SkipNavContent />
    </React.Fragment>
  );
};

export default Header;
