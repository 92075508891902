import React from "react";

import Box from "../Box";
import SEO from "../SEO";

export default function Page({
  children,
  title,
  description,
  banner,
  pathname,
  ...otherProps
}) {
  return (
    <Box as="main" {...otherProps}>
      <SEO
        title={title}
        description={description}
        pathname={pathname}
        banner={banner}
      />
      {children}
    </Box>
  );
}
