import React from "react";

import Box from "../Box";

import { ReactComponent as Svg } from "../../../images/logo.svg";

export default function Logo({ sx, ...otherProps }) {
  return (
    <Box
      sx={{ maxWidth: "24vw", svg: { height: "auto" }, ...sx }}
      {...otherProps}
    >
      <Svg width="200" height="35" />
    </Box>
  );
}
